import React from 'react'
//Components
import { Box, Flex, Text } from '../../../../atoms'
import { motion, AnimatePresence } from 'framer-motion'

interface FeatureItemProps {
  isActive: boolean
  isPassed: boolean
  isLast: boolean
  name: string
  content: string
  itemDuration: number
  onClick: () => void
}

const FeatureItem = React.forwardRef<HTMLDivElement, FeatureItemProps>(
  ({ name, content, isLast, isActive, isPassed, onClick, itemDuration }, ref) => {
    return (
      <Flex
        ref={ref}
        flex={['0 0 85%', '0 0 85%', '0 0 auto']}
        flexDirection={['column', 'column', 'row']}
        alignItems={['stretch', 'stretch', 'flex-start']}
        position={'relative'}
        pb={!isLast ? [0, 0, 9] : undefined}
        style={{
          scrollSnapAlign: 'center',
        }}
      >
        {!isLast && (
          <>
            <Box
              display={['none', 'none', 'block']}
              position={'absolute'}
              top={'20px'}
              left={'10px'}
              width={'1px'}
              height={'100%'}
              backgroundImage={'linear-gradient(to bottom, transparent 50%, #464867 50%)'}
              backgroundSize={'1px 20px'}
            >
              <AnimatePresence>
                {isActive && (
                  <motion.div
                    transition={{ duration: itemDuration, type: 'tween', ease: 'linear' }}
                    initial={{
                      height: '0%',
                    }}
                    animate={{ height: '100%' }}
                    style={{
                      backgroundColor: '#8B01E8',
                    }}
                  />
                )}
              </AnimatePresence>
              {isPassed && (
                <div
                  style={{
                    height: '100%',
                    backgroundColor: '#8B01E8',
                  }}
                />
              )}
            </Box>
            <Box
              display={['block', 'block', 'none']}
              position={'absolute'}
              top={'15px'}
              left={'10px'}
              width={'100%'}
              height={'1px'}
              backgroundImage={'linear-gradient(to right, transparent 50%, #464867 50%)'}
              backgroundSize={'20px 1px'}
            >
              <AnimatePresence>
                {isActive && (
                  <motion.div
                    transition={{ duration: itemDuration, type: 'tween', ease: 'linear' }}
                    initial={{
                      width: '0%',
                    }}
                    animate={{ width: '100%' }}
                    style={{
                      height: '100%',
                      backgroundColor: '#8B01E8',
                    }}
                  />
                )}
              </AnimatePresence>
              {isPassed && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#8B01E8',
                  }}
                />
              )}
            </Box>
          </>
        )}
        <Box mt={'5px'} flex={'0 0 auto'}>
          <Box
            width={21}
            height={21}
            borderRadius={'circle'}
            p={'4px'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={isActive ? 'purple' : 'transparent'}
          >
            <Box
              width={'100%'}
              height={'100%'}
              borderRadius={'circle'}
              backgroundColor={isPassed || isActive ? 'purple' : 'navy'}
            />
          </Box>
        </Box>
        <Box flex={['0 0 auto', '0 0 auto', '1']} mt={[3, 3, 0]} pl={[2, 2, 5]} pr={5} clickable onClick={onClick}>
          <Box>
            <Text
              fontSize={[3, 4, 4]}
              fontWeight={'semi'}
              lineHeight={1.4}
              color={'white'}
              opacity={!isActive ? 0.64 : 1}
            >
              {name}
            </Text>
          </Box>
          <Box display={['none', 'none', 'block']}>
            <AnimatePresence>
              <motion.div
                initial={'nonActive'}
                variants={{
                  nonActive: { height: 0, opacity: 0 },
                  active: { height: 'auto', opacity: 1 },
                }}
                animate={isActive ? 'active' : 'nonActive'}
                exit={'nonActive'}
                transition={{ duration: 0.25 }}
              >
                <Box pt={3}>
                  <Text fontSize={2} color={'white'}>
                    {content}
                  </Text>
                </Box>
              </motion.div>
            </AnimatePresence>
          </Box>
          <Box display={['block', 'block', 'none']} pt={3}>
            <Text fontSize={[1, 2]} color={'white'} opacity={isActive ? 1 : 0.64}>
              {content}
            </Text>
          </Box>
        </Box>
      </Flex>
    )
  }
)

export default FeatureItem
