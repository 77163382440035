import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
//Types
import { SectionBaseProps, SectionFeaturesStep } from '../../../types/sections'
//Components
import { motion } from 'framer-motion'
import { Box, Heading, Image } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { FeaturesCol, FeaturesItems } from './FeaturesStep.styled'
import FeatureItem from './FeatureItem'

export interface FeaturesStepProps extends SectionBaseProps {
  data: SectionFeaturesStep
}

// in seconds (for framer motion)
const delay = 8

const FeaturesStep: React.FC<FeaturesStepProps> = ({ data }) => {
  const { id, sectionId, title, features } = data
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const featuresWrapperRef = useRef<HTMLDivElement | null>(null)
  const featuresRef = useRef<{ [key: number]: HTMLDivElement | null }>({})

  const [activeItemIndex, setActiveItemIndex] = useState<number>(0)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const backgroundVariants = useMemo(() => {
    return {
      active: {
        opacity: 1,
      },
      nonActive: {
        opacity: 0,
      },
    }
  }, [])

  const assetVariants = useMemo(() => {
    return {
      active: {
        top: 0,
        opacity: 1,
      },
      nonActive: {
        top: 50,
        opacity: 0,
      },
    }
  }, [])

  const goToIdx = useCallback((index) => {
    setActiveItemIndex(index)
    featuresWrapperRef?.current?.scrollTo({
      top: 0,
      left: featuresRef.current[index]?.offsetLeft,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      const idx = features.length - 1 === activeItemIndex ? 0 : activeItemIndex + 1
      goToIdx(idx)
    }, delay * 1000)

    return () => {
      resetTimeout()
    }
  }, [activeItemIndex])

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      color={'white'}
      py={[80, 80, 120]}
      overflow={'hidden'}
      backgroundColor={'dark'}
      backgroundImage={[
        'radial-gradient(circle at 0% 20%,rgba(139,1,232,0.25),rgba(255,255,255,0) 35%),radial-gradient(circle at 50% 60%,rgba(109,216,252,0.15),rgba(255,255,255,0) 35%),radial-gradient(circle at 100% 35%,rgba(248,144,144,0.20),rgba(255,255,255,0) 35%)',
        'radial-gradient(circle at 0% 30%,rgba(139,1,232,0.25),rgba(255,255,255,0) 15%),radial-gradient(circle at 75% 65%,rgba(109,216,252,0.15),rgba(255,255,255,0) 18%),radial-gradient(circle at 100% 45%,rgba(248,144,144,0.20),rgba(255,255,255,0) 18%)',
      ]}
    >
      <Grid>
        {/* ROWS */}
        <Box mb={[48, 80, 100]}>
          <Row>
            <Col xs={12} md={9}>
              <Heading as={'h3'} color={'white'}>
                {title}
              </Heading>
            </Col>
          </Row>
        </Box>
        <Row reverse>
          <Col xs={12} md={6}>
            <Box position={'sticky'} top={120} borderRadius={['medium', 'large']} overflow={'hidden'}>
              <Box pb={`calc(100% * ${features[activeItemIndex]?.background?.sizes?.aspectRatio || 1})`}>
                {features.map((f, index) => (
                  <React.Fragment key={f.id}>
                    {f.background && (
                      <motion.div
                        key={f.id}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 2,
                          width: '100%',
                          height: '100%',
                        }}
                        animate={activeItemIndex === index ? 'active' : 'nonActive'}
                        variants={backgroundVariants}
                        transition={{ duration: 0.3 }}
                      >
                        <Image
                          alt={f.background.alt || ''}
                          image={f.background.gatsbyImageData}
                          style={{ overflow: 'hidden', borderRadius: '32px', width: '100%', height: '100%' }}
                          imgStyle={{ borderRadius: '32px' }}
                        />
                      </motion.div>
                    )}
                  </React.Fragment>
                ))}
              </Box>

              <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} zIndex={2} overflow={'hidden'}>
                {features.map((f, index) => (
                  <React.Fragment key={f.id}>
                    {f.asset && (
                      <motion.div
                        style={{ position: 'absolute', zIndex: 2, width: '100%', height: '100%', top: 0, left: 0 }}
                        animate={activeItemIndex === index ? 'active' : 'nonActive'}
                        variants={assetVariants}
                        transition={{ duration: 0.3 }}
                      >
                        <Box p={[40, 48, 56, 64]}>
                          <Image alt={f.asset.alt || f.name} image={f.asset.gatsbyImageData} objectFit={'contain'} />
                        </Box>
                      </motion.div>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </Col>
          <FeaturesCol xs={12} md={6}>
            <FeaturesItems
              ref={featuresWrapperRef}
              flexDirection={['row', 'row', 'column']}
              alignItems={'stretch'}
              mt={[6, 8, 0]}
              px={[4, 4, 0]}
            >
              {features.map((f, index) => (
                <FeatureItem
                  key={f.id}
                  ref={(el) => (featuresRef.current[index] = el)}
                  {...f}
                  isLast={index >= features.length - 1}
                  isPassed={activeItemIndex > index}
                  isActive={activeItemIndex === index}
                  itemDuration={delay}
                  onClick={() => goToIdx(index)}
                />
              ))}
            </FeaturesItems>
          </FeaturesCol>
        </Row>
      </Grid>
    </Box>
  )
}

export default FeaturesStep
