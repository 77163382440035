import React from 'react'
import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import { Flex, FlexProps } from '../../../atoms'
import { mediaQueries } from '../../../theme'

export const FeaturesCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;

  ${mediaQueries.greaterThan('md')`
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  `}

  ${mediaQueries.between('sm', 'md')`
    position: relative;
    
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      width: 2.4rem;
      height: 100%;
      top: 0;
      z-index:2;
      pointer-events: none;
    }
    &:before {
      left: 0;
      background: linear-gradient(to right, ${({ theme }) => theme.colors.dark} 0%, transparent 100%);
    }
    &:after {
      right: 0;
      background: linear-gradient(to left, ${({ theme }) => theme.colors.dark} 0%, transparent 100%);
    }
  `}
`

export const FeaturesItems: React.FC<FlexProps & { ref: React.Ref<HTMLDivElement> }> = styled(Flex)`
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${mediaQueries.greaterThan('md')`
    overflow: hidden;
  `}
`
